import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "components/core/Header";
import Footer from "components/core/Footer";
import Index from "components/core/Index";
import Home from "components/core/Home";
import PageNotFound from "components/core/PageNotFound";
import * as routes from "constants/routes";
import withAuthentication from "./withAuthentication";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

const App = () => {
  const Fiber = lazy(() => import("components/core/Fiber"));
  const Data = lazy(() => import("components/core/Data"));
  const BandwidthCalcPage = lazy(() => import("components/core/BandwidthCalc"));
  const BusinessConnect = lazy(() => import("components/core/BusinessConnect"));
  const SmartBiz = lazy(() => import("components/core/SmartBiz"));
  const Voice = lazy(() => import("components/core/Voice"));
  const About = lazy(() => import("components/core/About"));
  const AcceptableUse = lazy(() => import("components/core/AcceptableUse"));
  const Blog = lazy(() => import("components/core/Blog"));
  const BlogPost = lazy(() => import("components/core/BlogPost"));
  const Styleguide = lazy(() => import("components/core/Styleguide"));
  const Contact = lazy(() => import("components/core/Contact"));
  const Careers = lazy(() => import("components/core/Careers"));
  const Resources = lazy(() => import("components/core/Resources"));
  const GRIC = lazy(() => import("components/core/GRIC"));
  const MaXUC = lazy(() => import("components/core/MaXUC"));
  const VoIP = lazy(() => import("components/core/VoIP"));
  const Privacy = lazy(() => import("components/core/Privacy"));
  const Admin = lazy(() => import("components/admin/Admin"));
  const AdminSignUp = lazy(() => import("components/admin/AdminSignUp"));
  const PasswordForget = lazy(() => import("components/admin/PasswordForget"));
  const PasswordChange = lazy(() => import("components/admin/PasswordChange"));

  return (
    <Router>
      <Suspense fallback={<div className="content-loading no-body" />}>
        <div className="app-wrap">
          <Header />
          <Switch>
            <Route exact path={routes.Index} component={Index} />
            <Route exact path={routes.Home} component={Home} />
            <Route exact path={routes.Fiber} component={Fiber} />
            <Route exact path={routes.Data} component={Data} />
            <Route
              exact
              path={routes.BusinessConnect}
              component={BusinessConnect}
            />
            <Route exact path={routes.SmartBiz} component={SmartBiz} />
            <Route
              exact
              path={routes.BandwidthCalcPage}
              component={BandwidthCalcPage}
            />
            <Route exact path={routes.Voice} component={Voice} />
            <Route exact path={routes.About} component={About} />
            <Route
              exact
              path={routes.AcceptableUse}
              component={AcceptableUse}
            />
            <Route exact path={routes.Blog} component={Blog} />
            <Route path={`${routes.Blog}/:id`} component={BlogPost} />
            <Route exact path={routes.Styleguide} component={Styleguide} />
            <Route exact path={routes.Contact} component={Contact} />
            <Route exact path={routes.Careers} component={Careers} />
            <Route exact path={routes.Resources} component={Resources} />
            <Route exact path={routes.GRIC} component={GRIC} />
            <Route exact path={routes.MaXUC} component={MaXUC} />
            <Route exact path={`${routes.MaXUC}/*`} component={MaXUC} />
            <Route exact path={`${routes.VoIP}`} component={VoIP} />
            <Route exact path={routes.Privacy} component={Privacy} />
            <Route exact path={routes.Admin} component={Admin} />
            <Route path={`${routes.AdminSignUp}/:id`} component={AdminSignUp} />
            <Route
              exact
              path={routes.PasswordForget}
              component={PasswordForget}
            />
            <Route
              exact
              path={routes.PasswordChange}
              component={PasswordChange}
            />
            <Route exact path="/404" component={PageNotFound} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      </Suspense>
    </Router>
  );
};

ReactGA.initialize("UA-127515180-1");
ReactGA.pageview(window.location.pathname + window.location.search);
TagManager.initialize({ gtmId: "GTM-TKRG5KG" });

export default withAuthentication(App);
