export const Index = "/index";
export const Home = "/";
export const Fiber = "/fiber";
export const Data = "/data";
export const BusinessConnect = "/data/business-connect";
export const SmartBiz = "/data/smart-biz";
export const BandwidthCalcPage = "/bandwidth-calculator";
export const Voice = "/voice";
export const About = "/about";
export const AcceptableUse = "/acceptable-use";
export const Blog = "/blog";
export const Styleguide = "/styleguide";
export const Contact = "/contact";
export const Careers = "/careers";
export const Resources = "/resources";
export const GRIC = "/gric";
export const MaXUC = "/max";
export const VoIP = "/voip";
export const Admin = "/ac-admin";
export const Privacy = "/privacy";
export const AddUser = "/add-admin";
export const AdminSignUp = "/admin-signup";
export const PasswordForget = "/pass-forget";
export const PasswordChange = "/pass-change";
